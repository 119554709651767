import { Utils } from '../../helpers';
import * as mqttClient from '../connection/mqttConnection';
import { v4 as uuidv4 } from 'uuid';
import * as MP4Box from 'mp4box';
import Store from '../../store/Store';
import {
  setElementUpdated,
  setPlaybackBufferMeta,
  setStreamLoader,
  setMaxPeerCountForDevice,
} from '../../store/reducers/StreamingReducer';
import moment from 'moment';

// ====================
let videoElement = null;
const nextVideoElements = [];
let videoContainer = null;
let dataChannel = null;
let allConnectionData = null;
let peerConnection = null;
let currentPlayStartTime = '';
let audioMuteUnmute = false;
let mp4BoxFile = null;
const ctsArray = [];
let virtualTimeInterval = null;
let virtualCurrentTime = null;
let nextAvailTime;
let noVideoFromTimestamp;
let noVideo = null;
let timelineRef;
// let test_data_channel = 'dc-test';
// let video_data_channel = 'dc-video';
// let audio_data_channel = 'dc-audio';
let sendComplete = false;
let quality = '';

const generatePayload = (type, offerCandidate, data, subTopic) => {
  if (type === 'offer') {
    return {
      tid: new Date().getTime().toString(),
      to: data?.gatewayId,
      from: data?.accountId,
      msg: {
        resource: `ch/${data?.deviceId}/playback`,
        properties: {
          id: data?.sid,
          type: 'offer',
          sdp: offerCandidate,
        },
      },
      publish: subTopic,
    };
  }
  if (type === 'candidate') {
    return {
      tid: new Date().getTime().toString(),
      to: data?.gatewayId,
      from: data?.accountId,
      msg: {
        resource: `ch/${data?.deviceId}/playback`,
        properties: {
          id: data?.sid,
          type: 'candidate',
          ...JSON.parse(JSON.stringify(offerCandidate)),
        },
      },
      publish: subTopic,
    };
  }
};

// function ntpTimestampToUnixTimestamp(ntpTimestamp) {
//   /* global BigInt */
//   const bigIntNtpTimestamp = BigInt(ntpTimestamp);
//   const NTP_UNIX_EPOCH_DIFF = 2208988800n; // Seconds between 1900 and 1970
//   const TWO_TO_THE_32 = 2n ** 32n; // 2^32 to separate seconds and fractions

//   const seconds = bigIntNtpTimestamp >> 32n; // High 32 bits for seconds
//   const fractional = bigIntNtpTimestamp & (TWO_TO_THE_32 - 1n); // Low 32 bits for fractional part
//   const fractionalMilliseconds = Number((fractional * 1000n) / TWO_TO_THE_32);
//   const unixSeconds = Number(seconds - NTP_UNIX_EPOCH_DIFF);
//   const unixTimestamp = unixSeconds * 1000 + fractionalMilliseconds;

//   return unixTimestamp;
// }

const startConnection = async (deviceID) => {
  allConnectionData = mqttClient?.getMqttClient(false, deviceID);

  if (allConnectionData) {
    const { stun, turn } = allConnectionData?.serverDetails;
    const turnConfig = {
      iceServers: [
        {
          urls: `${stun.protocol}:${stun.host}:${stun.port}`,
        },
        {
          urls: `${turn.protocol}:${turn.host}:${turn.port}`,
          username: `${turn.userName}`,
          credential: `${turn.password}`,
        },
      ],
      // iceTransportPolicy: "all",
      // rtcpMuxPolicy: "require",
      // bundlePolicy: "balanced",
    };
    peerConnection = new RTCPeerConnection(turnConfig);

    peerConnection.createDataChannel('test_datachannel', {
      protocol: 'protocol',
    });
    const clientMQTT = allConnectionData?.mqttclient;
    const pubTopic = `a/notify/${allConnectionData?.gatewayId}`;
    const subTopic = `d/notify/${allConnectionData?.accountId}/${allConnectionData?.sid}`;
    const candidateArray = [];

    const checkVideoStartTime = () => {
      const { sourceBuffers } = videoElement;
      videoElement.currentTime = Math.max(
        ...Object.values(sourceBuffers).map((sb) => sb.startTime || 0),
      );
    };

    clientMQTT.subscribe(subTopic);

    // send offer
    const offer = await peerConnection.createOffer();
    await peerConnection.setLocalDescription(offer);
    mqttClient.sendWebRTCOffer(
      pubTopic,
      generatePayload('offer', offer.sdp, allConnectionData, subTopic),
    );

    // send local candidate
    peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        mqttClient.sendWebRTCCandidate(
          pubTopic,
          generatePayload(
            'candidate',
            event.candidate,
            allConnectionData,
            subTopic,
          ),
        );
      }
    };

    // peer connection state
    peerConnection.onconnectionstatechange = () => {
      console.log('playback peer connection ', peerConnection.connectionState);
    };

    // Manage mqtt messages
    clientMQTT.on('message', async (topic, byteMessage) => {
      if (!allConnectionData?.isLiveStream) {
        try {
          const strData = byteMessage.toString();
          const jsonData = JSON.parse(strData);
          const data = jsonData.msg.properties;
          if (!data) return;

          if (data?.code === '1011') {
            const maxPeerObj = {
              id: deviceID,
              isMaxPeer: true,
            };
            Store.dispatch(setMaxPeerCountForDevice(maxPeerObj));
          }
          switch (data.type) {
            case 'answer':
              const answer = new RTCSessionDescription({
                type: data.type,
                sdp: data.sdp,
              });
              await peerConnection
                ?.setRemoteDescription(answer)
                .then(() => console.log('Remote description set'))
                .catch(() => {});
              while (candidateArray.length > 0) {
                await peerConnection.addIceCandidate(
                  new RTCIceCandidate(candidateArray.shift()),
                );
              }
              break;
            case 'candidate':
              const candidate = new RTCIceCandidate({
                ...data,
                ...(!data.sdpMLineIndex && { sdpMLineIndex: 0 }),
              });
              if (peerConnection.remoteDescription)
                peerConnection.addIceCandidate(candidate);
              else candidateArray.push(candidate);
              break;
            default:
          }
        } catch (error) {}
      }
    });

    peerConnection.ondatachannel = (event) => {
      dataChannel = event.channel;
      const type = dataChannel.label;
      function appendBufferToSourceBuffer(targetVideoElement) {
        const { sourceBuffers, segmentQueues } = targetVideoElement;
        const sourceBuffer = sourceBuffers[dataChannel.label];
        const segmentQueue = segmentQueues[dataChannel.label];

        if (!sourceBuffer.updating && segmentQueue.length > 0) {
          sourceBuffer.appendBuffer(segmentQueue.shift());
        }
      }
      dataChannel.onopen = () => {
        console.log('=== data channel opened');
        sendPlayCommand(currentPlayStartTime, 'PLAY', quality);
      };

      dataChannel.onclose = () => {
        console.log('=== data channel closed');
        cleanupResources();
      };

      dataChannel.onerror = (error) => {
        console.log('==== data channel error: ', error);
      };

      dataChannel.onmessage = async (event) => {
        try {
          if (typeof event.data !== 'string') {
            const buffer = new Uint8Array(event.data);
            // console.log(
            //   '=== data channel received message size: ',
            //   buffer.byteLength,
            // );
            if (checkInitSegment(buffer)) {
              // TODO: Code is under observation will remove or update later
              // if (Object.keys(videoElement?.sourceBuffers)?.length > 0) {
              //   // console.log('next video elemt', nextVideoElements);
              //   videoElement.mediaSource.endOfStream();
              //   videoElement = nextVideoElements.at(-1);
              //   nextVideoElements.push(createVideoElement());
              // }

              const { sourceBuffers, segmentQueues, mediaSource } =
                videoElement;
              if (!segmentQueues[type]) segmentQueues[type] = [];
              const segmentQueue = segmentQueues[type];

              mp4BoxFile = await loadMp4BoxFile(buffer);
              // window.mp4BoxFile = mp4BoxFile;
              const mimeCodec = mp4BoxFile.getInfo().mime;
              // console.log('=== mime codec:', mimeCodec);
              if (!MediaSource.isTypeSupported(mimeCodec)) {
                console.error('Mime codec not supported: codec=', mimeCodec);
                return;
              }
              if (mediaSource.sourceBuffers.length) {
                // multiple resolution detected, but don't add new SourceBuffer
                segmentQueue.push(buffer);
                return;
              }
              sourceBuffers[type] = mediaSource.addSourceBuffer(mimeCodec);
              const sourceBuffer = sourceBuffers[type];
              sourceBuffer.addEventListener('updateend', (event) => {
                // console.log('=== source buffer update end');
                appendBufferToSourceBuffer(videoElement);
                const targetSourceBuffer = event.target;
                if (
                  typeof targetSourceBuffer.startTime === 'undefined' &&
                  targetSourceBuffer.buffered.length > 0
                ) {
                  targetSourceBuffer.startTime = event.target.buffered.start(0);
                  checkVideoStartTime();
                }
              });
              sourceBuffer.addEventListener('error', (error) => {
                console.error(
                  '=== source buffer error: ',
                  error,
                  videoElement?.error,
                );
              });
              segmentQueue.push(buffer);
            } else {
              const { sourceBuffers, segmentQueues } = videoElement;
              const segmentQueue = segmentQueues[type];

              if (!sourceBuffers[type]) {
                console.error(
                  '=== Source buffer not initialized, data received before the init segment is discarded.',
                );
                return;
              }
              segmentQueue.push(buffer);
              // const mp4Buffer = buffer.buffer;
              // mp4Buffer.fileStart = mp4BoxFile.lastFileStart;
              // mp4BoxFile.lastFileStart = mp4BoxFile.appendBuffer(mp4Buffer);
              appendBufferToSourceBuffer(videoElement);
            }
          } else {
            const receivedData = JSON.parse(event.data);
            const type = receivedData?.msg?.properties?.type;
            if (type === 'NO_VIDEO') {
              noVideoFromTimestamp = receivedData?.msg?.properties?.from;
              // check whether video is in playing state or not
              const isVideoPlaying = !!(
                videoElement?.currentTime > 0 &&
                !videoElement?.paused &&
                !videoElement?.ended &&
                videoElement?.readyState > 2
              );
              if (
                // !receivedData?.msg?.properties?.next_avail_time &&
                !isVideoPlaying
              ) {
                startClientSideTimer();
              }
              if (receivedData?.msg?.properties?.next_avail_time) {
                nextAvailTime = receivedData?.msg?.properties?.next_avail_time;
              }
            }

            if (type === 'SEND_COMPLETE') {
              sendComplete = true;
            }
            console.log(
              'Received data:',
              JSON.stringify(receivedData, null, 2),
            );
          }
        } catch (error) {
          console.error('=== media source error: ', error, videoElement?.error);
          // dataChannel.close();
          // mediaSource.endOfStream();
        }
      };
    };
  }
};

const checkInitSegment = (buffer) => {
  const ftypBox = String.fromCharCode.apply(null, buffer.subarray(4, 8));
  return ftypBox === 'ftyp';
};

const loadMp4BoxFile = (u8Buffer) => {
  return new Promise((resolve, reject) => {
    const mp4BoxFile = MP4Box.createFile(false);
    mp4BoxFile.onReady = (info) => {
      console.log('=== mp4 box info: ', info);
      resolve(mp4BoxFile);
    };
    mp4BoxFile.onError = reject;
    u8Buffer.buffer.fileStart = 0;
    mp4BoxFile.appendBuffer(u8Buffer.buffer);
    mp4BoxFile.flush();
  });
};

const addNewVideoElement = (event) => {
  console.log('addNewVideoElement ~ event:', event);
  try {
    const previouseVideoElement = event.target;
    previouseVideoElement.pause();
    // previouseVideoElement.removeEventListener('ended', addNewVideoElement);
    previouseVideoElement.removeEventListener('progress', handleVideoEvents);
    previouseVideoElement.removeEventListener('canplay', handleVideoEvents);
    URL.revokeObjectURL(previouseVideoElement.src);
    previouseVideoElement.remove();
    const nextVideoElement = nextVideoElements.shift();
    videoContainer.appendChild(nextVideoElement);
    nextVideoElement.play();
    Store.dispatch(setElementUpdated(true));
  } catch (err) {
    console.log('Error while adding new element', err);
  }
};

const createVideoElement = () => {
  const videoElement = document.createElement('video');

  videoElement.controls = false;
  videoElement.muted = audioMuteUnmute;
  videoElement.style.width = '100%';
  videoElement.id = 'playback-video';

  const mediaSource = new MediaSource();
  videoElement.mediaSource = mediaSource;
  videoElement.src = URL.createObjectURL(mediaSource);
  videoElement.sourceBuffers = {};
  videoElement.segmentQueues = {};
  mediaSource.addEventListener('sourceopen', () => {
    mediaSource.duration = 0;
  });
  // videoElement.addEventListener('ended', addNewVideoElement);
  videoElement.addEventListener('error', handleVideoEvents);
  videoElement.addEventListener('progress', handleVideoEvents);
  videoElement.addEventListener('canplay', handleVideoEvents);
  videoElement.addEventListener('waiting', handleVideoEvents);
  return videoElement;
};

const handleVideoEvents = (event) => {
  const isLoaderDisplay = Store.getState()?.streaming?.streamLoader;
  switch (event.type) {
    case 'canplay':
      if (isLoaderDisplay) {
        Store.dispatch(setStreamLoader(false));
      }
      break;

    case 'waiting':
      const bufferEnd =
        videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.end(0);

      if (
        parseInt(videoElement?.currentTime, 10) >= parseInt(bufferEnd, 10) &&
        sendComplete
      ) {
        handleNoVideoCases(event);
      } else {
        if (!isLoaderDisplay) {
          // Store.dispatch(setStreamLoader(true));
        }
      }
      break;

    case 'playing':
      if (isLoaderDisplay) {
        Store.dispatch(setStreamLoader(false));
      }
      break;

    case 'progress':
      assignBufferedMeta();
      break;

    case 'error':
      handleVideoError(event);
      break;

    default:
      break;
  }
};

const handleNoVideoCases = (event) => {
  // handling this function when video is playing and gap occurred.
  try {
    const bufferEnd =
      videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.end(0);
    noVideo.style.display = 'flex';
    virtualCurrentTime = parseInt(bufferEnd, 10);
    if (virtualCurrentTime <= nextAvailTime / 1000) {
      const previousVideoElement = event.target;
      previousVideoElement.pause();
      // previousVideoElement.removeEventListener('ended', addNewVideoElement);
      previousVideoElement.removeEventListener('progress', handleVideoEvents);
      previousVideoElement.removeEventListener('canplay', handleVideoEvents);
      URL.revokeObjectURL(previousVideoElement.src);
      previousVideoElement.remove();
      videoContainer = document.getElementById('remote-view-wrapper');
      videoElement = createVideoElement();
      nextVideoElements.push(createVideoElement());
      videoElement.autoplay = false;
      videoElement.preload = true;
      videoContainer.appendChild(videoElement);
      sendPlayCommand(Utils.getDate(nextAvailTime / 1000), 'PLAY');
      Store.dispatch(setElementUpdated(true));
    }
    // start client(local) side timer
    virtualTimeInterval = setInterval(() => {
      virtualCurrentTime += 1000 / 1000;
      if (virtualCurrentTime <= nextAvailTime / 1000) {
        timelineRef?.current?.$el?.moveTo(
          moment(Utils.getDate(virtualCurrentTime)),
        );
      } else {
        clearLocalTimer();
        noVideo.style.display = 'none';
        videoElement.play();
      }
    }, 1000);
  } catch (err) {}
};

const startClientSideTimer = () => {
  clearLocalTimer();
  const isLoaderDisplay = Store.getState()?.streaming?.streamLoader;
  noVideo.style.display = 'flex';
  if (isLoaderDisplay) {
    Store.dispatch(setStreamLoader(false));
  }
  virtualCurrentTime = noVideoFromTimestamp / 1000;
  let nextPlayCheckTime = virtualCurrentTime + 60;
  // start client(local) side timer
  virtualTimeInterval = setInterval(() => {
    virtualCurrentTime += 1000 / 1000;
    if (
      !nextAvailTime ||
      virtualCurrentTime <= parseInt(nextAvailTime / 1000, 10)
    ) {
      timelineRef?.current?.$el?.moveTo(
        moment(Utils.getDate(virtualCurrentTime)),
      );
    } else {
      clearLocalTimer();
      noVideo.style.display = 'none';
      sendPlayCommand(Utils.getDate(nextAvailTime / 1000), 'PLAY');
    }
    if (nextPlayCheckTime === virtualCurrentTime) {
      clearLocalTimer();
      sendPlayCommand(Utils.getDate(nextPlayCheckTime), 'PLAY');
    }
  }, 1000);
};

const assignBufferedMeta = () => {
  if (
    videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered.length > 0
  ) {
    const bufferStart =
      videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.start(0);
    const bufferEnd =
      videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.end(0);
    if (bufferStart && bufferEnd) {
      const obj = {
        id: allConnectionData?.deviceId,
        client: {
          start: parseInt(bufferStart, 10) * 1000,
          end: parseInt(bufferEnd, 10) * 1000,
          className: 'new-meta-buffer-class',
        },
      };
      Store.dispatch(setPlaybackBufferMeta(obj));
    }
  }
};

const handleVideoError = (event) => {
  console.log('video error', videoElement?.error);
};

export const startPlaybackProcess = (
  playTime,
  audioValue,
  timeline,
  deviceID,
  qualityData,
) => {
  quality = qualityData;
  currentPlayStartTime = playTime;
  audioMuteUnmute = audioValue;
  videoContainer = document.getElementById('remote-view-wrapper');
  videoElement = createVideoElement();
  nextVideoElements.push(createVideoElement());
  videoElement.autoplay = true;
  videoElement.preload = true;
  videoContainer.appendChild(videoElement);
  if (peerConnection?.connectionState !== 'connected') {
    startConnection(deviceID);
  }
  noVideo = document.getElementById('noVideo');
  timelineRef = timeline;
  const elementCreated = 'elementCreated';
  return elementCreated;
};

export const sendPlayCommand = (timestamp, command, qualityData) => {
  Store.dispatch(setPlaybackBufferMeta(null));
  quality = qualityData;
  sendComplete = false;
  const actualTime = Utils.getUnixDate(timestamp) * 1000;
  const uniqueId = uuidv4();
  if (dataChannel?.readyState === 'open') {
    const playPayload = {
      cid: uniqueId, //-- optional in case of Edge playback
      tid: new Date().getTime().toString(),
      to: allConnectionData?.deviceId,
      from: allConnectionData?.accountId,
      msg: {
        action: 'set',
        resource: 'edge/playback',
        properties: {
          uuid: allConnectionData?.sid, //-- optional in case of Edge playback
          org_id: allConnectionData?.orgId, //-- optional in case of Edge playback
          dev_id: allConnectionData?.deviceId, //-- optional in case of Edge playback
          start_time: actualTime, //-- epoch millisec
          duration: 30000, //-- +30000, -30000 (+ forward / - rewind)
          quality: quality, //-- HQ or SQ
          type: command,
        },
      },
    };
    console.log('playbackfw sendPlayCommand ~ playPayload:', playPayload);
    dataChannel?.send(JSON.stringify(playPayload));
  }
};

export const sendContinueCommand = (command, qualityData) => {
  quality = qualityData;
  sendComplete = false;
  const uniqueId = uuidv4();
  if (dataChannel?.readyState === 'open') {
    const playPayload = {
      cid: uniqueId, //-- optional in case of Edge playback
      tid: new Date().getTime().toString(),
      to: allConnectionData?.deviceId,
      from: allConnectionData?.accountId,
      msg: {
        action: 'set',
        resource: 'edge/playback',
        properties: {
          uuid: allConnectionData?.sid, //-- optional in case of Edge playback
          org_id: allConnectionData?.orgId, //-- optional in case of Edge playback
          dev_id: allConnectionData?.deviceId, //-- optional in case of Edge playback
          // start_time: actualTime, //-- epoch millisec
          duration: 30000, //-- +30000, -30000 (+ forward / - rewind)
          quality: quality, //-- HQ or SQ
          type: command,
        },
      },
    };
    const bufferEnd =
      videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.end(0);
    if (!(parseInt(nextAvailTime / 1000, 10) > parseInt(bufferEnd, 10))) {
      console.log(
        'playbackfw continue command ~ continuePayload:',
        playPayload,
      );
      dataChannel?.send(JSON.stringify(playPayload));
    }
  }
};

export const sendStopCommand = (command) => {
  const uniqueId = uuidv4();
  if (dataChannel?.readyState === 'open') {
    const playPayload = {
      cid: uniqueId, //-- optional in case of Edge playback
      tid: new Date().getTime().toString(),
      to: allConnectionData?.deviceId,
      from: allConnectionData?.accountId,
      msg: {
        action: 'set',
        resource: 'edge/playback',
        properties: {
          uuid: allConnectionData?.sid, //-- optional in case of Edge playback
          org_id: allConnectionData?.orgId, //-- optional in case of Edge playback
          dev_id: allConnectionData?.deviceId, //-- optional in case of Edge playback
          type: 'STOP',
        },
      },
    };
    dataChannel?.send(JSON.stringify(playPayload));
  }
};

export const stopPlaybackConnection = () => {
  if (peerConnection && dataChannel?.readyState === 'open') {
    peerConnection?.close();
    peerConnection.onicecandidate = null;
    peerConnection.ontrack = null;
    peerConnection = null;
    dataChannel = null;
  }
  clearLocalTimer();
  cleanupResources();
};

const cleanupResources = () => {
  cleanVideoElements();
  dataChannel = null;
  allConnectionData = null;
  peerConnection = null;
  currentPlayStartTime = '';
};

export const cleanVideoElements = () => {
  if (videoElement?.mediaSource?.readyState === 'open') {
    videoElement.mediaSource.endOfStream();
  }
  videoElement?.removeEventListener('ended', addNewVideoElement);
  videoElement?.removeEventListener('progress', handleVideoEvents);
  videoElement?.removeEventListener('canplay', handleVideoEvents);
  videoElement?.removeEventListener('error', handleVideoEvents);
  videoElement?.removeEventListener('waiting', handleVideoEvents);
  videoElement?.remove();
  videoElement = null;
  nextVideoElements.length = 0;
  videoContainer = null;
};

export const getPeerConnectionState = () => {
  return peerConnection;
};

export const clearLocalTimer = () => {
  if (virtualTimeInterval) {
    clearInterval(virtualTimeInterval);
  }
};
export const getSendComplete = () => {
  return sendComplete;
};
