import * as MP4Box from 'mp4box';
import { v4 as uuidv4 } from 'uuid';
import Store from '../../store/Store';
import {
  removeWSSConnections,
  setElementUpdated,
  setMultiLiveStreamLoader,
  setPlaybackBufferMeta,
  setWSSConnection,
  setWSSConnections,
} from '../../store/reducers/StreamingReducer';
import { Utils } from '../../helpers';
import moment from 'moment';

let virtualTimeInterval = null;
let virtualCurrentTime = null;
let nextAvailTime;
let noVideoFromTimestamp;
let noVideo = null;
let timelineRef;
let token;
// let websocket;
let websockets = {};
let orgID;
// let deviceID;
let cID;
let playbackUUID;

function startConnection(serverDetails, deviceID) {
  // const peerId = getOurId();
  const playbackServer = serverDetails?.timeline_server;
  let websocket;
  if (websockets?.[deviceID]) {
    websocket = websockets?.[deviceID];
  } else {
    websocket = new WebSocket(
      `${playbackServer.protocol}://${playbackServer.host}:${playbackServer.port}`,
    );
    websockets[deviceID] = websocket;
  }
  websocket.deviceId = deviceID;
  Store.dispatch(setWSSConnections({ id: deviceID, client: websocket }));
  const checkVideoStartTime = () => {
    const videoElement = document.getElementById(`playback-video${deviceID}`);
    const { sourceBuffers } = videoElement;
    videoElement.currentTime = Math.max(
      ...Object.values(sourceBuffers).map((sb) => sb.startTime || 0),
    );
  };

  websocket.onopen = async () => {
    // const con_time = Date.now() - time_dict[index];
    // console.log("=== websocket client connected", index, con_time / 1000);
    cID = uuidv4();
    playbackUUID = uuidv4();
    const registerObj = {
      appid: uuidv4(),
      token: token,
      cid: cID,
      tid: Date.now().toString(),
      to: deviceID,
      from: orgID,
      msg: {
        action: 'set',
        resource: 'media/handshake',
        properties: {
          uuid: playbackUUID,
          type: 'REGISTER',
        },
      },
    };
    if (websocket && websocket.readyState === 1) {
      const registerMsg = JSON.stringify(registerObj, null, 2);
      websocket.send(registerMsg);
      console.log('Registering with server, setting button value to Connect');
    }
  };

  websocket.onerror = (err) => {
    console.log('websocket error', err);
    websocket.close();
  };

  websocket.onclose = (e) => {
    console.log('cloud playback web socket closed', e);
    Store.dispatch(removeWSSConnections(websocket?.deviceId));
  };

  async function handleStream(event) {
    const videoElement = document.getElementById(`playback-video${deviceID}`);
    try {
      const data = event.data;
      const blob = await data.arrayBuffer();
      const buffer = new Uint8Array(blob);
      const type = 'video';

      function appendBufferToSourceBuffer(targetVideoElement) {
        const { sourceBuffers, segmentQueues } = targetVideoElement;
        const sourceBuffer = sourceBuffers[type];
        const segmentQueue = segmentQueues[type];

        if (sourceBuffer && !sourceBuffer.updating && segmentQueue.length > 0) {
          const nextSegment = segmentQueue.shift();
          if (nextSegment) {
            sourceBuffer.appendBuffer(nextSegment);
          }
        }
      }

      if (checkInitSegment(buffer)) {
        const { sourceBuffers, segmentQueues, mediaSource } = videoElement;
        if (!segmentQueues[type]) segmentQueues[type] = [];
        const segmentQueue = segmentQueues[type];

        const mp4BoxFile = await loadMp4BoxFile(buffer);
        window.mp4BoxFile = mp4BoxFile;
        const mimeCodec = mp4BoxFile.getInfo().mime;
        console.log('=== mime codec:', mimeCodec);
        if (!MediaSource.isTypeSupported(mimeCodec)) {
          console.error('Mime codec not supported: codec=', mimeCodec);
          return;
        }
        if (mediaSource.sourceBuffers.length) {
          console.log(
            "=== mutliple resolution detected, but don't add new SourceBuffer",
          );
          segmentQueue.push(buffer);
          return;
        }
        sourceBuffers[type] = mediaSource.addSourceBuffer(mimeCodec);
        const sourceBuffer = sourceBuffers[type];
        sourceBuffer.addEventListener('updateend', (event) => {
          // console.log("=== source buffer update end");
          appendBufferToSourceBuffer(videoElement);
          const targetSourceBuffer = event.target;
          if (
            typeof targetSourceBuffer.startTime === 'undefined' &&
            targetSourceBuffer.buffered.length > 0
          ) {
            targetSourceBuffer.startTime = event.target.buffered.start(0);
            checkVideoStartTime();
          }
        });
        sourceBuffer.addEventListener('error', (error) => {
          console.error('=== source buffer error: ', error, videoElement.error);
        });
        segmentQueue.push(buffer);
        // init_time = Date.now() - time_dict[index];
        // console.log("=== init time: ", index, init_time);
      } else {
        const { sourceBuffers, segmentQueues } = videoElement;
        const segmentQueue = segmentQueues[type];

        if (!sourceBuffers[type]) {
          console.error(
            '=== Source buffer not initialized, data received before the init segment is discarded.',
          );
          return;
        }
        segmentQueue.push(buffer);
        appendBufferToSourceBuffer(videoElement);
      }
    } catch (error) {
      console.error('=== media source error: ', error, videoElement?.error);
      //   mediaSource.endOfStream();
    }
  }

  websocket.onmessage = async (event) => {
    // console.log("=== received message: ", event.data);
    const data = event.data;
    if (data instanceof ArrayBuffer || data instanceof Blob) {
      // console.log("handling the stream...")
      await handleStream(event);
    } else {
      const responseMessage = JSON.parse(data);
      const type = responseMessage.msg.properties.type;
      const msgDeviceId = responseMessage?.from;
      console.log(JSON.stringify(responseMessage, null, 2));
      const videoElement = document.getElementById(`playback-video${deviceID}`);
      switch (type) {
        case 'REGISTERED':
          console.log('Registered with server');
          // sendPlayRequest();
          break;
        case 'READY':
          console.log('Ready to stream');
          break;
        case 'ERROR':
          console.error(event.data);
          break;
        case 'NO_VIDEO':
          noVideoFromTimestamp = responseMessage?.msg?.properties?.from;
          // check whether video is in playing state or not
          const isVideoPlaying = !!(
            videoElement?.currentTime > 0 &&
            !videoElement?.paused &&
            !videoElement?.ended &&
            videoElement?.readyState > 2
          );
          if (
            !responseMessage?.msg?.properties?.next_avail_time &&
            !isVideoPlaying
          ) {
            clearLocalTimer();
            // startClientSideTimer(msgDeviceId);
          }
          if (responseMessage?.msg?.properties?.next_avail_time) {
            clearLocalTimer();
            nextAvailTime = responseMessage?.msg?.properties?.next_avail_time;
            // startClientSideTimer(msgDeviceId);
          }
        default:
          break;
      }
    }
  };
}

function checkInitSegment(buffer) {
  // console.log("=== check init segment: ", buffer.byteLength);
  const ftypBox = String.fromCharCode.apply(null, buffer.subarray(4, 8));
  return ftypBox === 'ftyp';
}

function loadMp4BoxFile(u8Buffer) {
  return new Promise((resolve, reject) => {
    const mp4BoxFile = MP4Box.createFile(false);
    mp4BoxFile.onReady = (info) => {
      console.log('=== mp4 box info: ', info);
      resolve(mp4BoxFile);
    };
    mp4BoxFile.onError = reject;
    // u8Buffer.buffer.fileStart = 0;
    // mp4BoxFile.appendBuffer(u8Buffer.buffer);
    // mp4BoxFile.flush();
    if (checkInitSegment(u8Buffer)) {
      u8Buffer.buffer.fileStart = 0;
      mp4BoxFile.appendBuffer(u8Buffer.buffer);
      mp4BoxFile.flush();
    } else {
      reject('Non-init segment detected, skipping MP4Box processing.');
    }
  });
}

function createVideoElement(deviceID) {
  const videoElement = document.createElement('video');

  videoElement.controls = false;
  videoElement.muted = false;
  videoElement.style.width = '100%';
  videoElement.id = `playback-video${deviceID}`;
  const mediaSource = new MediaSource();
  videoElement.mediaSource = mediaSource;
  videoElement.src = URL.createObjectURL(mediaSource);
  videoElement.sourceBuffers = {};
  videoElement.segmentQueues = {};

  mediaSource.addEventListener('sourceopen', () => (mediaSource.duration = 0));

  // videoElement.addEventListener("ended", addNewVideoElement);
  videoElement.addEventListener('error', (event) =>
    handleVideoEvents(event, deviceID),
  );
  videoElement.addEventListener('progress', (event) =>
    handleVideoEvents(event, deviceID),
  );
  videoElement.addEventListener('canplay', (event) =>
    handleVideoEvents(event, deviceID),
  );
  videoElement.addEventListener('waiting', (event) =>
    handleVideoEvents(event, deviceID),
  );
  return videoElement;
}

const handleVideoEvents = (event, deviceID) => {
  // console.log('handleLoader ~ event:', event);
  const loaderData = Store.getState()?.streaming?.multiLiveStreamLoader;
  const isLoaderDisplay = loaderData?.[deviceID];
  const videoElement = document.getElementById(`playback-video${deviceID}`);
  switch (event.type) {
    case 'canplay':
      if (isLoaderDisplay) {
        const updatedObj = {
          deviceId: deviceID,
          isLoading: false,
        };
        Store.dispatch(setMultiLiveStreamLoader(updatedObj));
      }
      break;

    case 'waiting':
      // const bufferEnd =
      //   videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.end(0);

      // if (parseInt(videoElement?.currentTime, 10) >= parseInt(bufferEnd, 10)) {
      //   console.log('inside if condition ........');
      //   handleNoVideoCases(event, deviceID);
      // } else {
      if (!isLoaderDisplay) {
        const updatedObj = {
          deviceId: deviceID,
          isLoading: true,
        };
        Store.dispatch(setMultiLiveStreamLoader(updatedObj));
      }
      // }
      break;

    case 'playing':
      if (isLoaderDisplay) {
        const updatedObj = {
          deviceId: deviceID,
          isLoading: false,
        };
        Store.dispatch(setMultiLiveStreamLoader(updatedObj));
      }
      break;

    case 'progress':
      assignBufferedMeta(deviceID);
      break;

    case 'error':
      handleVideoError(event, deviceID);
      break;

    default:
      break;
  }
};

const handleNoVideoCases = (event, deviceID) => {
  // handling this function when video is playing and gap occurred.
  try {
    const videoElement = document.getElementById(`playback-video${deviceID}`);
    const bufferEnd =
      videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.end(0);
    noVideo.style.display = 'flex';
    virtualCurrentTime = parseInt(bufferEnd, 10);
    startClientSideTimer(deviceID);
  } catch (err) {}
};

const startClientSideTimer = (deviceID) => {
  const videoElement = document.getElementById(`playback-video${deviceID}`);
  const loaderData = Store.getState()?.streaming?.multiLiveStreamLoader;
  const isLoaderDisplay = loaderData?.[deviceID];
  noVideo.style.display = 'flex';
  if (isLoaderDisplay) {
    const updatedObj = {
      deviceId: deviceID,
      isLoading: false,
    };
    Store.dispatch(setMultiLiveStreamLoader(updatedObj));
  }
  const bufferEnd =
    videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.end(0);
  virtualCurrentTime = parseInt(bufferEnd, 10) || virtualCurrentTime;
  console.log('nextAvailTime is available', nextAvailTime, virtualCurrentTime);
  if (nextAvailTime && virtualCurrentTime <= nextAvailTime / 1000) {
    videoElement.pause();
    videoElement.removeEventListener('progress', (event) =>
      handleVideoEvents(event, deviceID),
    );
    videoElement.removeEventListener('canplay', (event) =>
      handleVideoEvents(event, deviceID),
    );
    URL.revokeObjectURL(videoElement.src);
    videoElement.src = '';
    videoElement.remove();
    sendCloudPlayRequest(
      Utils.getDate(parseInt(nextAvailTime / 1000, 10) + 1),
      timelineRef,
      false,
      deviceID,
    );
    Store.dispatch(setElementUpdated(true));

    virtualTimeInterval = setInterval(() => {
      virtualCurrentTime += 1000 / 1000;
      if (virtualCurrentTime <= nextAvailTime / 1000) {
        console.log('next avail time with timer');
        timelineRef?.current?.$el?.moveTo(
          moment(Utils.getDate(virtualCurrentTime)),
        );
      } else {
        console.log('reached to next avail time and play');
        clearLocalTimer();
        noVideo.style.display = 'none';
        videoElement.play();
      }
    }, 1000);
  } else {
    console.log('virtual current time', virtualCurrentTime, nextAvailTime);
    if (
      !nextAvailTime ||
      parseInt(nextAvailTime / 1000, 10) < virtualCurrentTime
    ) {
      let nextPlayCheckTime = virtualCurrentTime + 60;
      if (videoElement) {
        videoElement.pause();
        sendCloudStopRequest(deviceID);
        cleanupResources(deviceID);
      }
      virtualTimeInterval = setInterval(() => {
        virtualCurrentTime += 1000 / 1000;
        if (
          !nextAvailTime ||
          parseInt(nextAvailTime / 1000, 10) <= virtualCurrentTime
        ) {
          console.log('start client side timer is runnning');
          timelineRef?.current?.$el?.moveTo(
            moment(Utils.getDate(virtualCurrentTime)),
          );
        } else {
          console.log('stop the local timer and send play reuest');
          clearLocalTimer();
          noVideo.style.display = 'none';
          sendCloudPlayRequest(
            Utils.getDate(parseInt(nextAvailTime / 1000, 10) + 1),
            timelineRef,
            true,
            deviceID,
          );
        }
        if (nextPlayCheckTime === virtualCurrentTime) {
          console.log('send play request for next available time');
          clearLocalTimer();
          sendCloudPlayRequest(
            Utils.getDate(nextPlayCheckTime),
            timelineRef,
            true,
            deviceID,
          );
        }
      }, 1000);
    }
  }
};

const handleVideoError = (event, deviceID) => {
  const videoElement = document.getElementById(`playback-video${deviceID}`);
  console.log('video error', videoElement?.error, deviceID);
};

const assignBufferedMeta = (deviceID) => {
  const videoElement = document.getElementById(`playback-video${deviceID}`);
  if (
    videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered.length > 0
  ) {
    const bufferStart =
      videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.start(0);
    const bufferEnd =
      videoElement?.mediaSource?.activeSourceBuffers?.[0]?.buffered?.end(0);
    if (bufferStart && bufferEnd) {
      const obj = {
        id: deviceID,
        client: {
          start: parseInt(bufferStart, 10) * 1000,
          end: parseInt(bufferEnd, 10) * 1000,
          className: 'new-meta-buffer-class',
        },
      };
      Store.dispatch(setPlaybackBufferMeta(obj));
    }
  }
};

export const connectCloudPlayback = (getPlatformDetails, deviceId, orgId) => {
  // deviceID = deviceId;
  orgID = orgId;
  token = getPlatformDetails?.timeline_server?.token;
  startConnection(getPlatformDetails, deviceId);
};

export const sendCloudPlayRequest = (
  timestamp,
  timeline,
  autoPlay,
  deviceID,
) => {
  // const existingVideo = document.getElementById(`playback-video${deviceID}`);
  // if (existingVideo) {
  //   existingVideo.src = ''; // Release existing media source to free memory
  // }

  noVideo = document.getElementById('noVideo');
  timelineRef = timeline;
  const actualTime = Utils.getUnixDate(timestamp) * 1000;
  cID = uuidv4();
  playbackUUID = uuidv4();
  const videoContainer = document.getElementById(
    `remote-view-wrapper${deviceID}`,
  );
  const videoElement = createVideoElement(deviceID);
  videoElement.autoplay = autoPlay;
  videoContainer.appendChild(videoElement);
  Store.dispatch(setElementUpdated(true));

  const playObj = {
    cid: cID,
    tid: Date.now().toString(),
    to: deviceID,
    from: orgID,
    msg: {
      action: 'set',
      resource: 'media/handshake',
      properties: {
        uuid: playbackUUID,
        org_id: orgID,
        dev_id: deviceID,
        start_time: actualTime,
        duration: 60000,
        quality: 'SQ',
        type: 'PLAY',
      },
    },
  };
  const playMsg = JSON.stringify(playObj, null, 2);
  console.log('sendCloudPlayRequest ~ playMsg:', playMsg);
  const websocket = websockets?.[deviceID];
  if (websocket && websocket.readyState === 1) {
    setTimeout(() => {
      websocket.send(playMsg);
    }, 1500);
  }
  const elementCreated = 'elementCreated';
  return elementCreated;
};

export const sendCloudContinueRequest = (deviceID) => {
  const contunueObj = {
    cid: cID,
    tid: Date.now().toString(),
    to: deviceID,
    from: orgID,
    msg: {
      action: 'set',
      resource: 'media/streaming',
      properties: {
        uuid: playbackUUID,
        org_id: orgID,
        dev_id: deviceID,
        duration: 60000,
        quality: 'SQ',
        type: 'CONTINUE',
      },
    },
  };
  const continueMsg = JSON.stringify(contunueObj, null, 2);
  const websocket = websockets?.[deviceID];
  if (websocket && websocket.readyState === 1) {
    websocket.send(continueMsg);
  }
};

export const sendCloudStopRequest = (deviceID) => {
  const stopObj = {
    cid: cID,
    tid: Date.now().toString(),
    to: deviceID,
    from: orgID,
    msg: {
      resource: 'media/streaming',
      properties: {
        uuid: playbackUUID,
        org_id: orgID,
        dev_id: deviceID,
        type: 'STOP',
      },
    },
  };
  const stopMsg = JSON.stringify(stopObj, null, 2);
  console.log('sendCloudStopRequest ~ stopMsg:', stopMsg);
  const websocket = websockets?.[deviceID];
  if (websocket && websocket.readyState === 1) {
    websocket.send(stopMsg);
  }

  // sendCloudDisConnectRequest();
};

export const sendCloudDisConnectRequest = (deviceID) => {
  const disconnectObj = {
    cid: cID,
    tid: Date.now().toString(),
    to: deviceID,
    from: orgID,
    msg: {
      resource: 'media/streaming',
      properties: {
        uuid: playbackUUID,
        org_id: orgID,
        dev_id: deviceID,
        type: 'DISCONNECT',
      },
    },
  };

  const disconnectMsg = JSON.stringify(disconnectObj, null, 2);
  console.log('sendCloudDisConnectRequest ~ DISCONNECT:', disconnectMsg);
  const websocket = websockets?.[deviceID];
  if (websocket && websocket.readyState === 1) {
    websocket.send(disconnectMsg);
  }
  disconnectWithWebSocket(deviceID);
};

export const disconnectWithWebSocket = (deviceID) => {
  Store.dispatch(removeWSSConnections(deviceID));
  if (websockets?.[deviceID]) {
    websockets[deviceID].close();
    delete websockets[deviceID];
  }
};

export const cleanupResources = (deviceID) => {
  const videoElement = document.getElementById(`playback-video${deviceID}`);
  if (!videoElement) return;

  if (videoElement?.mediaSource?.readyState === 'open') {
    videoElement.mediaSource.endOfStream();
  }
  videoElement?.removeEventListener('progress', (event) =>
    handleVideoEvents(event, deviceID),
  );
  videoElement?.removeEventListener('canplay', (event) =>
    handleVideoEvents(event, deviceID),
  );
  videoElement?.removeEventListener('error', (event) =>
    handleVideoEvents(event, deviceID),
  );
  videoElement?.removeEventListener('waiting', (event) =>
    handleVideoEvents(event, deviceID),
  );
  URL.revokeObjectURL(videoElement.src);
  videoElement.src = '';
  videoElement?.remove();
};

export const clearLocalTimer = () => {
  clearInterval(virtualTimeInterval);
};
