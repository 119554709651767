import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import _ from 'lodash';
import { Responsive, WidthProvider } from 'react-grid-layout';

import PlaybackGridItem from './PlaybackGridItem';
// import EditCamera from './EditCamera';
import {
  getIsLeftMenuOpen,
  getSelectedView,
  getViewList,
  setSelectedView,
  setViewList,
} from '../../../store/reducers/ViewsReducer';
import { constants, LocalStorageKeysEnum, Utils } from '../../../helpers';
import { SiteSpinner } from '../../../components/common';

import './LiveGridStructure.scss';
import 'react-grid-layout/css/styles.css';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const COLS = { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 };
const BREAKPOINTS = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 };
const PlaybackGridStructure = (props) => {
  const {
    activeTime,
    timeZone,
    orgDetails,
    devicesData,
    noContentDeviceIds,
    currentViewDeviceList,
    setCurrentViewDeviceList,
    selectedViewDevices,
    setCustomViewLayout,
    cdnValue,
    selectedEventTime,
    viewId,
    clearLayout,
    setClearLayout,
    resetLayout,
    setResetLayout,
    setEditLayout,
    isViewUpdateSaved,
    viewUpdateSavedCallback,
    fullscreen,
    deviceCapabilitiesByDeviceId,
    moveTimeline,
    liveSnapshot,
  } = props;
  const gridRef = useRef();
  const cameraTileContainerRef = useRef();
  const dispatch = useDispatch();
  const viewList = useSelector(getViewList);
  const selectedView = useSelector(getSelectedView);
  const isLeftMenuOpen = useSelector(getIsLeftMenuOpen);
  const numOfDevices = currentViewDeviceList?.length || 0;
  const [draggingItemId, setDraggingItemId] = useState(null);
  const [hoveredDeviceId, setHoveredDeviceId] = useState(null);
  const [isChangedSize, setIsChangedSize] = useState(false);
  const [loading, setLoading] = useState(true);
  const [mounted, setMounted] = useState(false);
  const [currentBreakpoint, setCurrentBreakpoint] = useState('lg');
  const [currentRowHeight, setCurrentRowHeight] = useState(40);
  const [currentGridHeight, setCurrentGridHeight] = useState('auto');
  const [currentGridWidth, setCurrentGridWidth] = useState('auto');
  const [toolbox, setToolbox] = useState({
    lg: [],
  });
  const [currentViewId, setCurrentViewId] = useState(null);
  const [pendingLayouts, setPendingLayouts] = useState(
    Utils.getLocalStorageData(LocalStorageKeysEnum.cameraViewLayout) || null,
  );
  const [currentLayout, setCurrentLayout] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showButtonLoader, setShowButtonLoader] = useState(false);
  const [layouts, setLayouts] = useState({});

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
      setIsChangedSize(!isChangedSize);

      if (devicesData && devicesData.length) {
        setLoading(false);
      }
    }, 200);

    const handleResize = () => {
      setCurrentGridHeight(cameraTileContainerRef?.current?.clientHeight);
      setCurrentGridWidth(cameraTileContainerRef?.current?.clientWidth);
      setCurrentRowHeight(cameraTileContainerRef?.current?.clientHeight / 9);
    };

    setCurrentRowHeight(cameraTileContainerRef?.current?.clientHeight / 12);

    window.addEventListener('resize', handleResize);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (clearLayout === true) {
      setEditLayout(_.isObject(pendingLayouts) && pendingLayouts[viewId]);
      setCurrentLayout(
        _.isObject(pendingLayouts)
          ? pendingLayouts[viewId]
          : Array.isArray(selectedView?.customLayout) &&
              selectedView.customLayout.length > 0
            ? selectedView.customLayout
            : null,
      );
      setCurrentViewId(viewId);
      setClearLayout(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearLayout]);

  useEffect(() => {
    if (isViewUpdateSaved === true) {
      if (_.isObject(pendingLayouts) && _.isObject(pendingLayouts[viewId])) {
        delete pendingLayouts[viewId];
        Utils.setLocalStorageData(
          LocalStorageKeysEnum.cameraViewLayout,
          pendingLayouts,
        );
      } else {
        Utils.removeLocalStorageData(LocalStorageKeysEnum.cameraViewLayout);
      }

      setHasUnsavedChanges(false);

      if (
        viewUpdateSavedCallback &&
        typeof viewUpdateSavedCallback === 'function'
      ) {
        viewUpdateSavedCallback(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isViewUpdateSaved]);

  useEffect(() => {
    if (resetLayout === true) {
      setCurrentLayout(
        selectedView?.customLayout?.length > 0
          ? [...selectedView?.customLayout]
          : [],
      );
      setCustomViewLayout(null);
      setResetLayout(false);
      if (_.isObject(pendingLayouts) && _.isObject(pendingLayouts[viewId])) {
        delete pendingLayouts[viewId];
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetLayout]);

  useEffect(() => {
    let currentCustomLayout = [];

    if (!viewId) {
      return;
    }

    setCurrentViewId(viewId);
    const layoutsFromLS = Utils.getLocalStorageData(
      LocalStorageKeysEnum.cameraViewLayout,
    );

    if (_.isObject(layoutsFromLS) && _.isObject(layoutsFromLS[viewId])) {
      currentCustomLayout = structuredClone(layoutsFromLS[viewId]);
      Utils.vmsLogger().log('useEffect  viewId: ', viewId);
      setEditLayout(true);
    } else if (
      Array.isArray(selectedView?.customLayout) &&
      selectedView.customLayout.length > 0
    ) {
      currentCustomLayout = structuredClone(selectedView.customLayout);
    }

    Utils.vmsLogger().log(
      'useEffect  currentCustomLayout: ',
      currentCustomLayout,
    );
    // setEditLayout(true);
    setPendingLayouts(structuredClone(layoutsFromLS));

    setCurrentLayout(structuredClone(currentCustomLayout));
    // setCustomViewLayout(currentCustomLayout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewId]);

  const handleLayoutChange = (layout, oldItem, newItem) => {
    const currentPendingLayouts = _.isObject(pendingLayouts)
      ? pendingLayouts
      : null;

    if (!Utils.areCommonPropsEqual(oldItem, newItem)) {
      let viewLayouts;

      if (_.isObject(currentPendingLayouts)) {
        if (_.isObject(currentPendingLayouts[viewId])) {
          currentPendingLayouts[viewId] = layout;

          viewLayouts = structuredClone(currentPendingLayouts);
        } else {
          viewLayouts = {
            ...pendingLayouts,
            [viewId]: layout,
          };
        }
      } else {
        viewLayouts = {
          [viewId]: layout,
        };
      }

      setCustomViewLayout(layout);
      setCurrentLayout(layout);
      setHasUnsavedChanges(true);

      if (
        !Utils.setLocalStorageData(
          LocalStorageKeysEnum.cameraViewLayout,
          viewLayouts,
        )
      ) {
        Utils.vmsLogger().error(
          '*** ERROR: could not save new layout for view: ',
          viewId,
        );
      }

      setEditLayout(true);
    }
  };

  const onLayoutChange = (layout, layouts) => {
    let gridItems = document.getElementsByClassName('device-stack-item');
    Utils.vmsLogger().log('gridItems: ', gridItems);
    Utils.vmsLogger().log('layout: ', layout);
    Utils.vmsLogger().log('layouts: ', layouts);
  };

  const onBreakpointChange = (breakpoint) => {
    Utils.vmsLogger().log('breakpoint: ', breakpoint);

    setCurrentBreakpoint(breakpoint);
    setToolbox({
      ...toolbox,
      [breakpoint]: toolbox[breakpoint] || toolbox[currentBreakpoint] || [],
    });
  };

  const getDeviceInfo = (deviceId) => {
    if (devicesData?.length) {
      const deviceData = devicesData.find(
        (device) => device.deviceId === deviceId,
      );
      return deviceData;
    }
    return null;
  };

  const handleDragStart = (item) => {
    setDraggingItemId(item.getKey());
  };

  const handleDragEnd = async () => {
    setDraggingItemId(null);
    if (gridRef.current) {
      const reorderedDeviceIds = gridRef.current
        .getItems()
        .map((elem) => elem.getKey());
      setCurrentViewDeviceList(reorderedDeviceIds);

      const updatedView = Utils.getUpdatedViewPayload(
        selectedView,
        reorderedDeviceIds.filter((a) => !a.includes('edit')),
      );
      const response = await axios.put(
        `/partner/orgs/${orgDetails?.orgId}/userViews/${selectedView.viewId}`,
        updatedView,
        {
          params: {
            orgId: orgDetails?.orgId,
          },
          ...Utils.requestHeader(),
        },
      );
      const resData = response?.data;

      if (resData?.meta.code === 200) {
        dispatch(setSelectedView(updatedView));
        const updatedViewList = viewList.map((view) => {
          if (view.viewId === selectedView.viewId) {
            return updatedView;
          } else {
            return view;
          }
        });
        dispatch(setViewList(updatedViewList));
      }
    }
  };

  const calculateDimensions = (rows, columns) => {
    const container = document.getElementsByClassName(
      'main-content-container',
    )?.[0];
    if (!container) return; // Ensure container exists before proceeding.

    const totalContainerWidth = container.clientWidth || 0;
    const containerHeight = container.clientHeight || 0;

    if (!totalContainerWidth || !containerHeight) return; // If no dimensions are found, return early.

    // Adjust width based on left menu visibility
    const availableWidth = isLeftMenuOpen
      ? totalContainerWidth - 100
      : totalContainerWidth; // Assuming the left menu is 300px wide
    const aspectRatio = 16 / 9; // Default aspect ratio
    const rowWidth = availableWidth / columns; // Width of each column
    const columnHeight = containerHeight / rows; // Height of each row

    let tileWidth = rowWidth;
    let tileHeight = tileWidth / aspectRatio;

    // Adjust width and height to maintain the aspect ratio within the container
    if (tileHeight > columnHeight) {
      tileHeight = columnHeight;
      tileWidth = tileHeight * aspectRatio;
    }

    return {
      rowWidth: tileWidth * columns,
      columnHeight: tileHeight * rows,
    };
  };

  const isNoVideoContent = (deviceId) => {
    const findIndex = noContentDeviceIds?.findIndex(
      (device) => device === deviceId,
    );
    return findIndex > -1;
  };

  const handleGridItemResize = useCallback(
    (l, oldLayoutItem, layoutItem, placeholder) => {
      const heightDiff = layoutItem?.h - oldLayoutItem?.h;
      const widthDiff = layoutItem?.w - oldLayoutItem?.w;
      const changeCoef = oldLayoutItem?.w / oldLayoutItem?.h;

      if (!heightDiff || !widthDiff) return;

      if (Math.abs(heightDiff) < Math.abs(widthDiff)) {
        layoutItem.h = layoutItem?.w / changeCoef;
        placeholder.h = layoutItem?.w / changeCoef;
      } else {
        layoutItem.w = layoutItem?.h * changeCoef;
        placeholder.w = layoutItem?.h * changeCoef;
      }
    },
    [],
  );

  const generateDOM = () => {
    let DOMContent;
    let shouldLayoutBeUpdated = false;
    let currentCustomLayout;
    const layoutsFromLS = Utils.getLocalStorageData(
      LocalStorageKeysEnum.cameraViewLayout,
    );

    if (_.isObject(layoutsFromLS) && _.isObject(layoutsFromLS[viewId])) {
      currentCustomLayout = structuredClone(layoutsFromLS[viewId]);
    }
    const viewLayouts =
      currentCustomLayout?.length > 0
        ? [...currentCustomLayout]
        : currentLayout?.length > 0
          ? [...currentLayout]
          : selectedView?.customLayout?.length > 0
            ? [...selectedView?.customLayout]
            : null;

    Utils.vmsLogger().log('*** generateDOM viewId: ', viewId);

    if (Array.isArray(pendingLayouts) && _.isObject(pendingLayouts[viewId])) {
      Utils.vmsLogger().log(
        '*** generateDOM pendingLayouts[viewId]: ',
        pendingLayouts[viewId],
      );
    } else {
      Utils.vmsLogger().log('*** generateDOM pendingLayouts[viewId]: ', null);
    }

    Utils.vmsLogger().log(
      '*** generateDOM selectedView?.customLayout: ',
      selectedView?.customLayout,
    );

    Utils.vmsLogger().log('*** generateDOM currentLayout: ', currentLayout);
    Utils.vmsLogger().log('*** generateDOM has viewLayouts: ', viewLayouts);
    Utils.vmsLogger().log('*** selectedView: ', selectedView);

    DOMContent = currentViewDeviceList.map((deviceId, i) => {
      const deviceInfo = getDeviceInfo(deviceId);
      const hasVideoContent = isNoVideoContent(deviceId);
      const itemLayout = viewLayouts?.find((layout) => layout.i === deviceId);
      shouldLayoutBeUpdated = !_.isObject(itemLayout);

      return (
        <div
          key={deviceId}
          onMouseEnter={() => setHoveredDeviceId(deviceId)}
          onMouseLeave={() => setHoveredDeviceId(null)}
          className={`item-grid device-stack-item ${
            draggingItemId !== deviceId ? '' : 'dragged-item'
          }
                ${hoveredDeviceId === deviceId ? 'add-hover' : ''}
              `}
          data-grid={{
            x: itemLayout?.x ?? (numOfDevices === 1 ? 0 : (i * 3) % 12),
            y: itemLayout?.y ?? 0,
            w: itemLayout?.w ?? (numOfDevices === 1 ? 5 : 3),
            h: itemLayout?.h ?? (numOfDevices === 1 ? 5 : 3),
            resizeHandles: itemLayout?.resizeHandles,
            minH: 2,
            minW: 2,
          }}
          onResize={handleGridItemResize}
        >
          {deviceInfo &&
            deviceCapabilitiesByDeviceId?.[deviceId]?.isEdgeRecording !==
              null &&
            deviceCapabilitiesByDeviceId?.[deviceId]?.isEdgeRecording !==
              undefined && (
              <PlaybackGridItem
                key={deviceId}
                layout={deviceId}
                device={deviceInfo}
                deviceId={deviceId}
                activeTime={activeTime}
                timeZone={timeZone}
                orgDetails={orgDetails}
                isNoVideoContent={hasVideoContent}
                cdnValue={cdnValue}
                selectedEventTime={selectedEventTime}
                isEdgeRecording={
                  deviceCapabilitiesByDeviceId[deviceId].isEdgeRecording
                }
              />
              // ) : (
              //   <EditCamera />
            )}
        </div>
      );
    });

    if (shouldLayoutBeUpdated === true) {
      let gridItems = document.getElementsByClassName('device-stack-item');
      Utils.vmsLogger().log('*** needLayoutUpdate gridItems: ', gridItems);
      // TODO: delete this later
      // Utils.vmsLogger().log(
      //   '*** needLayoutUpdate gridItems[0].dataset.grid.value: ',
      //   gridItems[0].dataset?.grid?.value
      // );
    }

    return DOMContent;
  };

  return (
    <div
      ref={cameraTileContainerRef}
      className={`camera-tile-container playback-mode ${
        activeTime && (moveTimeline || liveSnapshot) ? 'visibility-hidden' : ''
      }`}
      style={{
        position: 'absolute',
        top: '0px',
        left: '0px',
        right: '0px',
      }}
    >
      {loading ? (
        <div className="loading-screen" style={{ position: 'absolute' }}>
          <SiteSpinner />
          <div className="simple-label">
            {constants.CAMERAS_VIDEO_CAMERA_LOADING_LABEL}
          </div>
        </div>
      ) : (
        <ResponsiveReactGridLayout
          ref={gridRef}
          className="devices-stack mb-4"
          layouts={layouts}
          useCSSTransforms={mounted}
          preventCollision={true}
          measureBeforeMount={false}
          compactType={null}
          onDragStop={handleLayoutChange}
          onLayoutChange={(layout, layouts) => onLayoutChange(layout, layouts)}
          onBreakpointChange={onBreakpointChange}
          onResizeStop={handleLayoutChange}
          breakpoints={BREAKPOINTS}
          cols={COLS}
          rowHeight={currentRowHeight}
          width={currentGridWidth}
          height={currentGridHeight}
          maxRows={9}
          margin={[10, 10]}
        >
          {generateDOM()}
        </ResponsiveReactGridLayout>
      )}
    </div>
  );
};

export default PlaybackGridStructure;
